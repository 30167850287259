.header-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  height: min-content;
}

.header {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-logo {
  height: 40px;
  margin-left: -60px;
}

.nav {
  display: flex;
  gap: 15px;
  align-items: center;
}

.nav-icon {
  height: 30px;
  width: 30px;
}

.nav-icon-play {
  height: 25px;
  width: 25px;
}

/* .nav-item {
  font-size: 20px;
  line-height: 20px;
  position: relative;
}

.nav-item::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  top: 110%;
  background-color: hsl(var(--foreground));
  transition: transform 0.2s ease-in-out;
  transform: scale(0);
}

.nav-item:hover::after {
  transform: scale(1);
}

.nav-shop-now {
  color: hsl(var(--primary));
}

.nav-shop-now::after {
  background-color: hsl(var(--primary));
} */
