.qrcode-trigger {
  position: fixed;
  right: 40px;
  bottom: 20px;
  padding: 10px;
  padding-bottom: 5px;
  border: 1px solid hsl(var(--border));
  border-radius: 10px;
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;
  z-index: 100;
  background-color: hsl(var(--background));
}

.qrcode-trigger:hover {
  box-shadow: 0px 2px 5px 0px rgba(107, 107, 107, 0.2),
    0px 10px 10px 0px rgba(107, 107, 107, 0.2),
    0px 20px 20px 0px rgba(107, 107, 107, 0.1),
    0px 30px 10px 0px rgba(107, 107, 107, 0.04),
    0px 30px 20px 0px rgba(107, 107, 107, 0.01);
}

.qrcode-icon {
  height: 50px;
  width: 50px;
}

.qr-overlay {
  position: fixed;
  background-color: rgba(107, 107, 107, 0.2);
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

.qr-modal {
  position: relative;
  z-index: 100;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid hsl(var(--border));
  box-shadow: 0px 2px 5px 0px rgba(107, 107, 107, 0.2),
    0px 10px 10px 0px rgba(107, 107, 107, 0.2),
    0px 20px 20px 0px rgba(107, 107, 107, 0.1),
    0px 30px 10px 0px rgba(107, 107, 107, 0.04),
    0px 30px 20px 0px rgba(107, 107, 107, 0.01);
  background-color: hsl(var(--background));
}

.qr-modal-close {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 20px;
  width: 20px;
  background-color: hsl(var(--foreground));
  color: hsl(var(--background));
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.qr-title {
  font-size: 23px;
  font-weight: 500;
  text-align: center;
  margin-top: 10px;
  color: hsl(var(--primary));
}

.qr-image {
  height: 250px;
}

@media screen and (max-width: 700px) {
  .qrcode-icon {
    height: 30px;
    width: 30px;
  }
}
