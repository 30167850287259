.footer {
  background-color: hsl(var(--secondary));
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 10px;
  color: hsl(var(--foreground));
  scroll-snap-align: end;
  padding-bottom: 100px;
}

.footer.no-snap {
  scroll-snap-align: none;
}

.footer-logo {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.ql-title {
  font-size: 15px;
  font-weight: bold;
  white-space: nowrap;
  color: hsl(var(--foreground));
}

.ql-links {
  display: flex;
  gap: 10px;
  flex-direction: column;
  font-size: 15px;
  color: hsl(var(--foreground));
}

@media screen and (max-width: 800px) {
  .footer-container {
    padding-top: 200px;
  }
}
