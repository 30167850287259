.main {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
}

.main-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-snap-align: start;
}

.contact-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-snap-align: center;
}

.features {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.section-full {
  min-height: 100vh;
  width: 100%;
  scroll-snap-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.main-title {
  font-size: 60px;
  line-height: 65px;
  width: 950px;
  text-align: center;
  font-weight: 500;
}

.down-fade-mount {
  transform: translateY(-100px);
  opacity: 0;
  transition: all 1.5s ease-in-out;
}

.down-fade-mount.active {
  opacity: 1;
  transform: translateY(0);
}

.main-shopping {
  color: hsl(var(--primary));
}

.main-subtitle {
  font-size: 18px;
  line-height: 23px;
  width: 600px;
  text-align: center;
  color: hsl(var(--muted-foreground));
}

.main-image {
  height: 500px;
}

.main-small-title {
  font-size: 40px;
  width: 800px;
  text-align: center;
  font-weight: 500;
}

.demo-image {
  width: 210px;
}

.contact-us {
  display: flex;
  align-items: center;
  gap: 40px;
}

.contact-us-image {
  width: 400px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 300px;
}

.contact-form input,
.contact-form textarea {
  padding: 10px 15px;
  border: 1px solid hsl(var(--border));
  border-radius: 5px;
  font-size: 15px;
  resize: none;
}

.contact-form label {
  font-weight: 500;
  display: flex;
  flex-direction: column;
  color: hsl(var(--muted-foreground));
}

.submit-btn {
  border-radius: 5px;
  background-color: hsl(var(--primary));
  color: hsl(var(--primary-foreground));
  font-weight: 500;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 100%;
}

.two-section-wrapper {
  display: flex;
  gap: 100px;
  align-items: center;
  padding-left: 150px;
  padding-right: 150px;
}

.feature {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.feature-icon {
  height: 70px;
  width: 70px;
  color: hsl(var(--primary));
}

.feature-title {
  font-size: 40px;
  line-height: 50px;
  font-weight: 500;
}

.feature-subtitle {
  font-size: 22px;
  line-height: 28px;
  color: hsl(var(--muted-foreground));
}

.feature-image1,
.feature-image2,
.feature-image3,
.feature-image4,
.feature-image5 {
  height: 500px;
}

@media screen and (max-width: 1000px) {
  .main-title {
    font-size: 40px;
    line-height: 45px;
    width: 700px;
  }
  .main-image {
    height: 400px;
  }
  .feature-image1,
  .feature-image2,
  .feature-image3,
  .feature-image4,
  .feature-image5 {
    height: 400px;
  }
  .feature-icon {
    height: 50px;
    width: 50px;
  }

  .feature-title {
    font-size: 40px;
    line-height: 43px;
  }

  .feature-subtitle {
    font-size: 20px;
    line-height: 23px;
  }

  .contact-us-image {
    width: 300px;
  }
  .contact-form {
    width: 350px;
  }
  .main-subtitle {
    font-size: 15px;
    line-height: 18px;
  }
}

@media screen and (max-width: 700px) {
  .main-title {
    font-size: 38px;
    line-height: 40px;
    width: 500px;
  }
  .main-image {
    height: 300px;
  }
  .feature-image1,
  .feature-image2,
  .feature-image3,
  .feature-image4,
  .feature-image5 {
    height: 350px;
  }
  .feature-icon {
    height: 50px;
    width: 50px;
  }

  .feature-title {
    font-size: 35px;
    line-height: 37px;
  }

  .feature-subtitle {
    font-size: 15px;
    line-height: 18px;
  }

  .two-section-wrapper {
    gap: 30px;
    padding-left: 50px;
    padding-right: 50px;
  }
  .contact-us-image {
    width: 250px;
  }
  .contact-form {
    width: 300px;
  }
}

@media screen and (max-width: 500px) {
  .main-title {
    font-size: 38px;
    line-height: 40px;
    width: 350px;
  }
  .main-image {
    height: 300px;
  }
  .feature-image1,
  .feature-image2,
  .feature-image3,
  .feature-image4,
  .feature-image5 {
    height: 250px;
  }
  .feature-icon {
    height: 40px;
    width: 40px;
  }

  .feature-title {
    font-size: 30px;
    line-height: 35px;
  }

  .feature-subtitle {
    font-size: 13px;
    line-height: 15px;
  }

  .two-section-wrapper {
    padding-left: 50px;
    padding-right: 50px;
    flex-direction: column;
  }
  .contact-us-image {
    display: none;
  }
  .contact-form {
    width: 300px;
  }
}
