@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@font-face {
  font-family: "GrowwSans";
  src: url("./font/GrowwSans.woff2") format("woff2-variations");
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 221.2 83.2% 53.3%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 26.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 80.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 221.2 83.2% 53.3%;
    --radius: 0.5rem;
  }
}

* {
  font-family: "GrowwSans", "Inter", sans-serif;
  border: hsl(var(--border));
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: hsl(var(--background));
  color: hsl(var(--foreground));
}

.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 5px;
}

.container {
  max-width: 1100px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

a {
  text-decoration: none;
  color: hsl(var(--foreground));
}

.prose {
  padding: 0px 20px;
  max-width: 1000px;
  margin: auto;
  text-align: justify;
}

.prose h1 {
  font-size: 25px;
  margin-bottom: 10px;
}

.prose h2 {
  font-size: 22px;
  margin-bottom: 8px;
  margin-top: 30px;
}

.prose h3 {
  font-size: 18px;
  margin-bottom: 8px;
  margin-top: 30px;
}

.prose p {
  margin-bottom: 10px;
}

.prose li {
  margin-left: 30px;
}

.prose table,
th,
td {
  border-collapse: collapse;
  border: 1px solid black;
  padding: 2px 10px;
}

.prose table {
  margin-bottom: 20px;
  margin-top: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
