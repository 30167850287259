.delete-my-account-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.back {
  height: 25px;
  width: 25px;
  background-color: hsl(var(--secodary));
  border: 1px solid hsl(var(--border));
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: -20px;
}

.back-icon {
  height: 20px;
  width: 20px;
}

.d-page {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  position: relative;
}

.d-title {
  font-size: 20px;
  font-weight: 500;
}

.d-description {
  font-size: 18px;
  line-height: 22px;
  color: hsl(var(--muted-foreground));
}

.delete-my-account-page input,
.delete-my-account-page textarea {
  padding: 10px 15px;
  border: 1px solid hsl(var(--border));
  border-radius: 5px;
  font-size: 15px;
  resize: none;
  width: 100%;
}

.delete-my-account-page label {
  font-weight: 500;
  display: flex;
  flex-direction: column;
  color: hsl(var(--muted-foreground));
}

.submit-btn {
  border-radius: 5px;
  background-color: hsl(var(--primary));
  color: hsl(var(--primary-foreground));
  font-weight: 500;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: auto;
}

.submit-btn:disabled {
  background-color: hsl(var(--border));
  color: hsl(var(--primary-foreground));
}

.otps {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;
}

.otp {
  height: 50px;
  width: 50px;
  display: flex;
  text-align: center;
}
